import React from 'react';
import Layout from '../../components/Layout/layout';
import RedundancyPayCalculator from '../../Views/Portal/Tools/Calculators/Redundancy-Pay-Calculator/Redundancy-Pay-Calculator';

const RedundancyPayCalculatorPage: React.FC = () => {
  return (
    <Layout>
      <RedundancyPayCalculator />
    </Layout>
  );
};

export default RedundancyPayCalculatorPage;
